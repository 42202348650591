var render = function render(){var _vm=this,_c=_vm._self._c;return _c('styled-slideout',{attrs:{"value":_vm.value,"nested":_vm.submitRequestDialogOpen},on:{"update:nested":function($event){_vm.submitRequestDialogOpen=$event},"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"nested",fn:function(){return [_c('div',{staticClass:"pa-5"},[_c('hub-spot-form',{attrs:{"form-id":"5e37dff0-e17d-4c80-a4e0-d9a52126646f","form-submit-callback":_vm.handlePreSubmit,"field-values":{
                    'TICKET.ticket___buyerbridge_id': _vm.currentDealer.id,
                    'TICKET.form___dealer_name': _vm.currentDealer.name,
                    'TICKET.agency_id': _vm.currentDealer.agency.id,
                    'TICKET.agency_name': _vm.currentDealer.agency.name,
                    'TICKET.ticket_contact_email': _vm.user.email,
                    'email': _vm.user.email
                }}})],1)]},proxy:true}])},[_c('div',{staticClass:"pa-5"},[_c('h2',[_vm._v("Request a New Site Provider")]),_c('p',[_vm._v(" Each new site provider requires development resources to map out specific attributes of the site like the VDP URL structure and the position of the VIN on those pages. As a result, we will need to schedule a task with our support team to accommodate new providers. ")]),_c('p',[_vm._v(" Note: "),_c('em',[_vm._v(" new site provider setups can take up to 72 hours to complete ")])]),_c('p',[_vm._v("Once we receive your request here’s what to expect:")]),_c('styled-list',{attrs:{"align-middle":""}},[_c('div',{staticClass:"mb-3"},[_vm._v(" Our team will follow up with any questions pertaining to the new provider and will set expectations on turnaround time. ")]),_c('div',{staticClass:"mb-3"},[_vm._v(" Once the provider is setup they will let you know that you can continue onboarding with additional instructions. ")])]),_c('p',{staticClass:"mb-5"},[_vm._v(" If you are comfortable with the steps above and are ready to submit a new request please click the button below to open a new request. ")]),_c('div',{staticClass:"text-xs-center"},[_c('styled-button',{attrs:{"outline":""},on:{"click":function($event){_vm.submitRequestDialogOpen = true}}},[_vm._v(" Submit Request ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }