<template>
    <styled-slideout
        :value="value"
        :width="750"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2 class="mb-2">
                Tag Install Request Messaging
            </h2>
            <p>
                Use the example messaging below to coordinate the installation of EventFlowPLUS onto
                {{ currentDealer.site_base_url }} with {{ siteProvider.name }}.
            </p>
            <div
                v-if="siteProvider.support_phone || siteProvider.support_email"
                class="contact-panel">
                <p class="contact-title">
                    {{ siteProvider.name }} Contact Information
                </p>
                <div class="layout wrap">
                    <data-field
                        v-if="siteProvider.support_email"
                        class="mr-5"
                        no-indent>
                        <template #label>
                            Email
                        </template>
                        <a
                            :href="`mailto:${siteProvider.support_email}`"
                            target="_blank">
                            {{ siteProvider.support_email }}
                        </a>
                    </data-field>
                    <data-field
                        v-if="siteProvider.support_phone"
                        no-indent>
                        <template #label>
                            Phone
                        </template>
                        <a
                            :href="`tel:${siteProvider.support_phone}`"
                            target="_blank">
                            {{ siteProvider.support_phone }}
                        </a>
                    </data-field>
                </div>
            </div>
            <div class="switcher-wrapper">
                <v-switch
                    v-model="viewSwitch"
                    class="tag-installation-switcher"
                    color="white" />
                <div class="switcher-text">
                    Check here if you're a third-party and need to obtain approval from {{ currentDealer.name }} before
                    coordinating with {{ siteProvider.name }}.
                </div>
            </div>
            <div v-if="!viewSwitch">
                <h3 class="pt-3 mb-2">
                    Request for Install
                </h3>
                <p>
                    Send the message below to {{ siteProvider.name }} to have them install the EventFlowPLUS tag onto {{ currentDealer.site_base_url }}.
                </p>
                <copy-text-area
                    hide-download
                    custom-padding="35"
                    :email-subject="`${currentDealer.name} request RE: Tag Installation`"
                    class="mb-4">
                    <p>
                        Hello {{ siteProvider.name }}!
                    </p>
                    <p>
                        We're in the process of setting up our advertising system with {{ currentDealer.name }}.
                        To finalize the process we'd like to install our first-party tracking script on {{ currentDealer.site_base_url }}.
                    </p>
                    <br>
                    <p>
                        You can find the code to install at the link below:<br>
                        {{ eventFlowPlusSetupUrl }}
                    </p>
                    <br>
                    <p>
                        Thanks!<br>
                        The {{ currentDealer.agency.name }} Team
                    </p>
                </copy-text-area>
            </div>
            <styled-list
                v-else
                class="mt-4">
                <div class="mb-3">
                    <h3 class="mb-2">
                        Account Approval
                    </h3>
                    <p>
                        Send the message below to a contact at {{ currentDealer.name }} that's designated to approve requests to {{ siteProvider.name }}.
                    </p>
                    <copy-text-area
                        hide-download
                        custom-padding="35"
                        :email-subject="`Obtaining Approval`"
                        class="mb-4">
                        <p>
                            Greetings!
                        </p>
                        <p>
                            In order to finalize the installation of our advertising signals system that's designated to work under
                            {{ currentDealer.site_base_url }} we need your approval to work with {{ siteProvider.name }}.
                        </p>
                        <br>
                        <p>
                            Please REPLY ALL with "Approved" to proceed with the process!
                        </p>
                        <br>
                        <p>
                            Thanks!<br>
                            The {{ currentDealer.agency.name }} Team
                        </p>
                    </copy-text-area>
                </div>
                <div class="mb-3">
                    <h3 class="mb-2">
                        Request for Install
                    </h3>
                    <p>
                        Forward the approval along with the message below to {{ siteProvider.name }} to have
                        them install the EventFlowPLUS tag onto {{ currentDealer.site_base_url }}.
                    </p>
                    <copy-text-area
                        hide-download
                        custom-padding="35"
                        :email-subject="`${currentDealer.name} request RE: Tag Installation`"
                        class="mb-4">
                        <p>
                            Hello {{ siteProvider.name }}!
                        </p>
                        <p>
                            We're in the process of setting up our advertising system with {{ currentDealer.name }}.
                            To finalize the process we'd like to install our first-party tracking script on {{ currentDealer.site_base_url }}.
                        </p>
                        <br>
                        <p>
                            You can find the code to install at the link below:<br>
                            {{ eventFlowPlusSetupUrl }}
                        </p>
                        <br>
                        <p>
                            Our contact at {{ currentDealer.name }} has approved this change in the thread below.
                        </p>
                        <br>
                        <p>
                            Thanks!<br>
                            The {{ currentDealer.agency.name }} Team
                        </p>
                    </copy-text-area>
                </div>
            </styled-list>
        </div>
    </styled-slideout>
</template>

<script>
import { mapState } from 'vuex';
import DataField from '@/components/globals/DataField';
import StyledList from '@/components/globals/StyledList';
import CopyTextArea from '@/components/globals/CopyTextArea';
import StyledSlideout from '@/components/globals/StyledSlideout';

export default {
    components: {
        DataField,
        StyledList,
        CopyTextArea,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        siteProvider: {
            type: Object,
            default: () => ({
                name: '',
                support_phone: '',
                support_email: ''
            })
        },
    },
    data() {
        return {
            viewSwitch: false
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
        eventFlowPlusSetupUrl() {
            const eventFlowPlusRoute = this.$router.resolve({
                name: 'event-flow-plus-setup',
                params: { uuid: this.currentDealer.uuid }
            });
            return window.location.origin + eventFlowPlusRoute.href;
        },
    }
};
</script>

<style lang="scss" scoped>
.contact-panel {
    padding: 30px;
    background-color: rgba($gray, 0.05);
}
.contact-title {
    font-weight: 600;
}
.switcher-wrapper {
    display: flex;
    align-items: center;
    margin-top: 15px;
}
</style>
<style lang="scss">
.tag-installation-switcher {
    .theme--light.v-input--switch__track.white--text {
        color: $blue-bg !important;
        opacity: 1;
    }
}
</style>
