<script>
import Icon from './Icon';
import colors from '../../helpers/colors';

export default {
    functional: true,
    render(h, { listeners }) {
        const icon = h(Icon, {
            props: {
                name: 'chevron-left',
                color: colors.blue,
                size: '11'
            },
            style: {
                verticalAlign: 'middle',
                marginRight: '2px'
            }
        });

        const text = h('span', 'BACK');

        return h('button', {
            class: ['back-button'],
            style: {
                color: colors.blue,
                fontWeight: '600'
            },
            on: {
                click: event => listeners.click(event)
            }
        },
        [ icon, text ]);
    }
};
</script>

<style lang="scss" scoped>
.back-button {
    transition: opacity 0.3s ease-in-out;
    &:hover {
        opacity: 0.5;
    }
}
</style>
