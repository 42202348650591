<template>
    <styled-slideout
        :value="value"
        :nested.sync="submitRequestDialogOpen"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Request a New Site Provider</h2>
            <p>
                Each new site provider requires development resources to map out
                specific attributes of the site like the VDP URL structure and
                the position of the VIN on those pages. As a result, we will
                need to schedule a task with our support team to accommodate new
                providers.
            </p>
            <p>
                Note:
                <em>
                    new site provider setups can take up to 72 hours to complete
                </em>
            </p>
            <p>Once we receive your request here’s what to expect:</p>
            <styled-list align-middle>
                <div class="mb-3">
                    Our team will follow up with any questions pertaining to the
                    new provider and will set expectations on turnaround time.
                </div>
                <div class="mb-3">
                    Once the provider is setup they will let you know that you
                    can continue onboarding with additional instructions.
                </div>
            </styled-list>
            <p class="mb-5">
                If you are comfortable with the steps above and are ready to
                submit a new request please click the button below to open a new
                request.
            </p>
            <div class="text-xs-center">
                <styled-button
                    outline
                    @click="submitRequestDialogOpen = true">
                    Submit Request
                </styled-button>
            </div>
        </div>
        <template #nested>
            <div class="pa-5">
                <hub-spot-form
                    form-id="5e37dff0-e17d-4c80-a4e0-d9a52126646f"
                    :form-submit-callback="handlePreSubmit"
                    :field-values="{
                        'TICKET.ticket___buyerbridge_id': currentDealer.id,
                        'TICKET.form___dealer_name': currentDealer.name,
                        'TICKET.agency_id': currentDealer.agency.id,
                        'TICKET.agency_name': currentDealer.agency.name,
                        'TICKET.ticket_contact_email': user.email,
                        'email': user.email
                    }" />
            </div>
        </template>
    </styled-slideout>
</template>

<script>
import HubSpotForm from './HubSpotForm';
import StyledList from './StyledList';
import StyledButton from './StyledButton';
import StyledSlideout from './StyledSlideout';
import { mapState } from 'vuex';

export default {
    components: {
        HubSpotForm,
        StyledList,
        StyledButton,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            submitRequestDialogOpen: false
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer,
        })
    },
    methods: {
        handlePreSubmit($form) {
            const $subjectField = $form.find('[name="TICKET.subject"]');
            const $providerField = $form.find('[name="TICKET.provider_name"]');
            const $contentField = $form.find('[name="TICKET.content"]');
            $contentField.val(`
            • User: ${this.user.name}
            • Organization: ${this.currentDealer.agency.name}
            • Account(s): ${this.currentDealer.name}
            • Account ID(s): ${this.currentDealer.id}
            • Notes:
            ${$contentField.val()}`);
            $subjectField.val(`New Site Provider - ${$providerField.val()} - ${this.currentDealer.agency.name} - ${this.currentDealer.name} - Support`);
        }
    }
};
</script>
