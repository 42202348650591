<template>
    <div>
        <new-tag-manager-provider-request-slideout
            v-model="showNewTagManagerDialog" />
        <tag-installation-instructions
            v-model="showTagInstructions"
            :site-provider="siteProvider" />
        <div class="holder">
            <div
                class="holder-left"
                :class="{'full-width': dnsIsCompleted}">
                <h2 class="holder-title">
                    EventFlowPLUS Tag Installation
                </h2>
                <p
                    v-if="dnsIsCompleted"
                    class="mb-4">
                    Once DNS records are successfully installed you will need to work with {{ currentDealer.name }}'s site provider to install the EventFlowPLUS tag
                    which is deisned to automatically collect signals such as leads & content views then send them securely through {{ currentDealer.site_base_url }}.
                    <br>
                    <br>
                    Please remove any previous versions of EventFlow that are installed on the website prior to installing the new tag for EventFlowPLUS.
                    If the EventFlow is in Google Tag Manager, delete/pause the tag or remove the trigger so it does not fire.
                    Having previous versions of EventFlow and the new EventFlowPLUS tag installed at the same time will have adverse effects on our ability to collect signals.
                </p>
                <p
                    v-else
                    class="mb-4">
                    Once DNS records are successfully installed you will need to work with {{ currentDealer.name }}'s site provider to install the new tag below.
                    For EventFlowPLUS to work properly and set first-party cookies this tag must be installed directly into the site and <i><b>cannot</b> be added through Google Tag Manager.</i>
                </p>
            </div>
            <div
                v-if="!dnsIsCompleted"
                class="holder-right">
                <button
                    :disabled="loading"
                    class="styled-button"
                    @click="getDNSRecords">
                    <icon
                        class="mr-2"
                        name="refresh"
                        color="#00A2EA"
                        size="12" />
                    Recheck Status
                </button>
                <button
                    disabled
                    class="ml-3 styled-button">
                    <icon
                        class="mr-2"
                        name="mail"
                        color="#00A2EA"
                        size="12" />
                    Email Instructions
                </button>
            </div>
        </div>
        <div class="main">
            <loader v-if="loading" />
            <div
                v-else-if="!dnsIsCompleted || error"
                class="process-failed">
                <div class="failed-left">
                    <div class="failed-title">
                        DNS Record Installation Not Completed
                    </div>
                    <div class="failed-text">
                        The two DNS records from the previous step must be successfully installed on {{ currentDealer.site_base_url }} before the tag installation can take place.
                        Please navigate back to the DNS record installation step to complete the installation of missing records before continuing this process.
                        If you believe you are seeing this message in error please click “Contact Support”.
                    </div>
                </div>
                <div class="failed-right">
                    <div
                        class="error-button"
                        @click.prevent="$store.dispatch('goToPreviousStep')">
                        Complete DNS Setup
                    </div>
                    <div
                        class="error-button"
                        @click.prevent="$store.dispatch('createOnboardingSupportTicket', 'Processing failed')">
                        Contact Support
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="dealerInAutoVertical">
                    <p>
                        To start, please select your site provider from the list below:
                    </p>
                    <div class="mb-5">
                        <div class="layout">
                            <v-autocomplete
                                v-model="siteProviderId"
                                class="styled-field flex xs12 md6 mb-2"
                                label="Select Site Provider"
                                :loading="loadingSiteProviders"
                                :items="siteProviders"
                                item-text="name"
                                item-value="id"
                                clearable />
                        </div>
                        <a
                            href="#"
                            @click.prevent="showNewTagManagerDialog = true">
                            Otherwise, click here if you don't see your provider
                        </a>
                    </div>
                    <div v-if="siteProviderId">
                        <event-flow-plus-tag-status
                            @show-instructions="showTagInstructions = true"
                            @set-status="setTagStatus" />
                    </div>
                </div>
            </div>
            <div class="mt-5 layout align-center">
                <back-button
                    @click="$store.dispatch('goToPreviousStep')" />
                <styled-button
                    class="ml-auto"
                    :loading="loading"
                    :disabled="!tagIsActive || loading"
                    @click="onContinue">
                    CONTINUE
                </styled-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Icon from '@/components/globals/Icon';
import Loader from '@/components/globals/Loader';
import BackButton from '@/components/globals/BackButton';
import StyledButton from '@/components/globals/StyledButton';
import EventFlowPlusTagStatus from '@/components/globals/EventFlowPlusTagStatus';
import TagInstallationInstructions from '@/components/dealers/settings/panels/TagInstallationInstructions';
import NewTagManagerProviderRequestSlideout from '@/components/globals/NewTagManagerProviderRequestSlideout';

export default {
    components: {
        Icon,
        Loader,
        BackButton,
        StyledButton,
        EventFlowPlusTagStatus,
        TagInstallationInstructions,
        NewTagManagerProviderRequestSlideout
    },
    data() {
        return {
            error: '',
            records: [],
            loading: false,
            siteProviders: [],
            tagIsActive: false,
            cnameIsValid: false,
            siteProviderId: null,
            loadingStatus: false,
            showTagInstructions: false,
            loadingSiteProviders: false,
            showNewTagManagerDialog: false,
        };
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerInAutoVertical'
        ]),
        txt() {
            const txt = this.records.find(record => record.type === 'TXT');
            if (txt) { return txt }
            return '';
        },
        dnsIsCompleted() {
            if (!this.cnameIsValid || this.txt) { return false }
            return true;
        },
        siteProvider() {
            return this.siteProviders.find(siteProvider => {
                return siteProvider.id === this.siteProviderId;
            });
        },
    },
    async mounted() {
        await this.getDNSRecords();
        await this.getSiteProviders();
    },
    methods: {
        setTagStatus(status) {
            this.tagIsActive = status;
        },
        async getDNSRecords() {
            this.loading = true;
            this.error = false;
            try {
                const response = await this.$apiRepository.getDNSRecords(this.currentDealer.id);
                this.records = response.data.records;
                this.cnameIsValid = response.data.valid;
            } catch(e) {
                this.error = e;
            } finally {
                this.loading = false;
            }
        },
        async getSiteProviders() {
            try {
                this.loadingSiteProviders = true;
                const response = await this.$apiRepository.getSiteProviders();
                this.siteProviders = response.data.data;
            } finally {
                this.loadingSiteProviders = false;
            }
        },
        async setSiteProvider() {
            this.loading = true;
            try {
                await this.$http.patch(`/dealers/${this.currentDealer.id}`, {
                    site_provider_id: this.siteProviderId
                });
                await this.$store.dispatch('updateCurrentDealer');
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async onContinue() {
            if (this.siteProviderId && this.siteProviderId !== this.currentDealer.site_provider_id) {
                await this.setSiteProvider();
            }
            this.$store.dispatch('goToNextStep');
        }
    }
};
</script>

<style lang="scss" scoped>
.holder {
    display: flex;
}
.holder-title {
    margin-bottom: 10px;
    font-size: 26px;
}
.holder-left {
    width: 64%;
}
.holder-right {
    width: 36%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.styled-button {
    border-radius: 6px;
    border: 1px solid $blue-bg;
    padding: 6px 14px;
    text-transform: uppercase;
    color: $blue-bg;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    &:disabled {
        opacity: 0.4;
    }
}
.process-failed {
    background-color: #F8CACA;
    border: 1px solid #E12020;
    padding: 24px 26px;
    font-size: 13px;
    display: flex;
}
.failed-title {
    font-weight: 600;
    margin-bottom: 15px;
}
.failed-left {
    width: 80%;
}
.failed-right {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.error-button {
    margin-top: 15px;
    width: 170px;
    padding: 4px 0;
    text-align: center;
    border-radius: 5px;
    color: $error-500;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid $error-500;
    &:first-of-type {
        background: $error-500;
        color: $white;
    }
}
.full-width {
    width: 100%;
}
</style>