import stripIndent from 'strip-indent';
import JSZip from 'jszip';
import downloadFile from './downloadFile';


export const getEventFlowReadmeText = () => {
    return stripIndent(`
        Paste the event flow script (event-flow-script.txt)
        immediately after the opening <head> tag on every page.
    `).trim();
};

export const getEventFlowTagReadmeText = (domain) => {
    return stripIndent(`Install this tag directly on every page of ${domain} as high in the <head> as possible.`).trim();
};

export const getEventFlowScript = (uuid, agencyName = 'BuyerBridge') => {
    return stripIndent(`
        <!-- ${agencyName} Event Flow -->
        <script>(function(w,d,s,v,u){if(w.bbEF)return;
        w._bbEF=w._bbEF||[{v:v}];w.bbEF=function(){_bbEF.push(arguments)}
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s);
        j.async=true;j.src=u;f.parentNode.insertBefore(j,f);
        })(window,document,'script',2.0,'https://eventflow.buyerbridge.io/app.js');
        bbEF('boot', '${uuid}');</script>
        <!-- End ${agencyName} Event Flow -->
    `).trim();
};

export const getEventFlowTagScript = (uuid, domain) => {
    return stripIndent(`
        <script>
          (function(w,v){w._bbEF=w._bbEF||[{v:v}];w.bbEF=w.bbEF||function(){_bbEF.push(arguments)}})(window,2.0);

          bbEF('boot', '${uuid}');
        </script>
        <script async type="text/javascript" src="https://${domain}/load"></script>
    `).trim();
};

export const downloadEventFlowCode = async(uuid) => {
    var zip = new JSZip();

    zip.file('readme.txt', getEventFlowReadmeText());

    zip.file('event-flow-script.txt', getEventFlowScript(uuid));

    // Generate the zip file asynchronously
    const content = await zip.generateAsync({ type: 'blob' });

    downloadFile(content, `${uuid}.zip`);
};

export const downloadEventFlowTagCode = async(uuid, domain) => {
    var zip = new JSZip();
    zip.file('readme.txt', getEventFlowTagReadmeText(domain));
    zip.file('event-flow-plus-tag.txt', getEventFlowTagScript(uuid));
    // Generate the zip file asynchronously
    const content = await zip.generateAsync({ type: 'blob' });
    downloadFile(content, `${uuid}.zip`);
};

export default downloadEventFlowCode;
