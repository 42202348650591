<template>
    <div>
        <div
            class="holder mb-4"
            :class="{'slim': slim}">
            <div class="holder-left">
                <p>
                    Install this tag directly on every page of {{ currentDealer.site_base_url }} as high in the <b>&lt;head&gt;</b> as possible.
                    Because it uses first-party cookies it <b>cannot</b> be added through Google Tag Manager.
                </p>
            </div>
            <div class="holder-right">
                <button
                    :disabled="loadingStatus"
                    class="styled-button"
                    @click="checkTagStatus">
                    <icon
                        class="mr-2"
                        name="refresh"
                        color="#00A2EA"
                        size="12" />
                    Recheck Status
                </button>
                <button
                    :disabled="loadingStatus"
                    class="ml-3 styled-button"
                    @click="$emit('show-instructions')">
                    <icon
                        class="mr-2"
                        name="mail"
                        color="#00A2EA"
                        size="12" />
                    Email Instructions
                </button>
            </div>
        </div>
        <loader v-if="loadingStatus" />
        <div v-else>
            <copy-text-area
                show-header
                header-label="EventFlowPLUS HTML Tag"
                :header-status="tagIsActive"
                :custom-padding="slim ? '30px 30px 60px 25' : '35'"
                download-filename="event-flow-plus-tag.txt"
                class="mb-4">
                <pre>{{ mainScript }}</pre>
            </copy-text-area>
            <p>
                If you're sending this to another party,
                feel free to use the link below which includes this script and instructions for the website's developer.
            </p>
            <p>
                <a
                    class="uuid-link"
                    :href="eventFlowPlusSetupUrl"
                    target="_blank">
                    {{ eventFlowPlusSetupUrl }}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '@/components/globals/Icon';
import Loader from '@/components/globals/Loader';
import CopyTextArea from '@/components/globals/CopyTextArea';
import { getEventFlowTagScript } from '@/helpers/eventFlowCode';

export default {
    components: {
        Icon,
        Loader,
        CopyTextArea
    },
    props: {
        slim: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            tagStatus: '',
            loadingStatus: false,
        };
    },
    computed: {
        ...mapState({
            currentDealer: state => state.dealer.currentDealer
        }),
        tagIsActive() {
            if (this.tagStatus === 'complete') { return true }
            return false;
        },
        conversionDomain() {
            return this.currentDealer.conversion_endpoints?.data[0]?.domain ?? '';
        },
        mainScript() {
            return getEventFlowTagScript(this.currentDealer.uuid, this.conversionDomain);
        },
        eventFlowPlusSetupUrl() {
            const eventFlowPlusRoute = this.$router.resolve({
                name: 'event-flow-plus-setup',
                params: { uuid: this.currentDealer.uuid }
            });
            return window.location.origin + eventFlowPlusRoute.href;
        },
    },
    watch: {
        tagStatus() {
            this.$emit('set-tag-status', this.tagStatus);
        }
    },
    mounted() {
        this.checkTagStatus();
    },
    methods: {
        async checkTagStatus() {
            this.loadingStatus = true;
            try {
                const response = await this.$apiRepository.getFeatureStatus(this.currentDealer.id, 'event_flow_plus_tag');
                this.tagStatus = response.data.status;
            } catch(e) {
                console.log(e);
            } finally {
                this.loadingStatus = false;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.holder {
    display: flex;
    &.slim {
        flex-direction: column;
    }
}
.holder-left {
    width: 64%;
}
.holder-right {
    width: 36%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.slim .holder-left {
    width: 100%;
    margin-bottom: 30px;
}
.slim .holder-right {
    width: 100%;
    justify-content: flex-start;
}
.styled-button {
    border-radius: 6px;
    border: 1px solid $blue-bg;
    padding: 6px 14px;
    text-transform: uppercase;
    color: $blue-bg;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    &:disabled {
        opacity: 0.4;
    }
}
.uuid-link {
    color: $blue-bg;
}
</style>